import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-custom-tooltip',
    templateUrl: './custom-tooltip.component.html',
    styleUrls: ['./custom-tooltip.component.scss'],
    standalone: false,
})
export class CustomTooltipComponent {
    @Input() content: string = '';
}
