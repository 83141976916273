import {
    Directive,
    Output,
    Input,
    EventEmitter,
    HostBinding,
    HostListener,
    OnInit,
} from '@angular/core';

@Directive({
    selector: '[appDragDrop]',
    standalone: false,
})
export class DragDropDirective implements OnInit {
    @Output() fileDropped = new EventEmitter<any>();
    @Input()
    appDragDrop: string;
    @Input()
    activeBackground: string = '#eaeaea';

    @HostBinding('style.background-color') public background = '';
    @HostBinding('style.opacity') public opacity = '1';

    //Dragover listener
    @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = this.activeBackground;
        this.opacity = '1';
    }
    //Dragleave listener
    @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = this.appDragDrop;
        this.opacity = '1';
    }
    //Drop listener
    @HostListener('drop', ['$event']) public ondrop(evt: DragEvent) {
        evt.preventDefault();
        evt.stopPropagation();
        this.background = this.appDragDrop;
        this.opacity = '1';
        const files = evt.dataTransfer!.files;
        if (files!.length > 0) {
            this.fileDropped.emit(files);
        }
    }

    ngOnInit() {
        this.background = this.appDragDrop;
    }
}
