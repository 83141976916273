import { Component, Inject, OnInit } from '@angular/core';
import { TourWithOrgSiteKey } from '@app/carrier/services/carrier.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorHandler } from '@app/core/services/error-handler.service';
import { ToursService } from '../../../../api/services/tours.service';
import { lastValueFrom, Observable } from 'rxjs';
import { BinaryFileType } from '../../../../api/models/binary-file-type';

@Component({
    selector: 'app-view-tour-dialog',
    templateUrl: './view-tour-dialog.component.html',
    styleUrls: ['./view-tour-dialog.component.scss'],
    standalone: false,
})
export class ViewTourDialogComponent implements OnInit {
    tour: TourWithOrgSiteKey;
    currentlyViewedPdf?: Blob;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        private data: {
            tour: TourWithOrgSiteKey;
            organizationSiteKey: string;
            context: 'consignor' | 'carrier' | 'consignee';
        },
        private translateService: TranslateService,
        private spinner: NgxSpinnerService,
        private tourService: ToursService,
        private errorHandler: ErrorHandler
    ) {}

    async ngOnInit() {
        this.tour = this.data.tour;
        if (this.tour.deliveryNoteKeys.length) {
            await this.loadTour();
        }
    }

    async loadTour(): Promise<void> {
        try {
            const blobData = await lastValueFrom(
                this.getBlobObservableForContext(this.data.context)!
            );

            this.currentlyViewedPdf = blobData;
        } catch (error) {
            this.translateService.instant('GLOBAL.ERROR.UNSPECIFIED');
        }
    }

    async downloadTour(): Promise<void> {
        try {
            await this.spinner.show();
            const url = window.URL.createObjectURL(this.currentlyViewedPdf!);
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.href = url;
            const bundleNumber = this.tour.tourNumber;
            a.download = bundleNumber.replace('.', '-') + '.pdf';
            a.click();
        } catch (error) {
            this.errorHandler.handleError(error);
        } finally {
            await this.spinner.hide();
        }
    }

    getBlobObservableForContext(
        context: string
    ): Observable<BinaryFileType> | undefined {
        switch (context) {
            case 'carrier':
                return this.tourService.getToursPdfs({
                    organizationSiteKey: this.data.organizationSiteKey,
                    tourKey: this.tour._key,
                });
            case 'consignee':
                return this.tourService.getToursPdfs({
                    organizationSiteKey: this.data.organizationSiteKey,
                    tourKey: this.tour._key,
                });
        }

        return undefined;
    }
}
