<div class="mat-mdc-dialog-content">
    <div class="question-icon">?</div>

    <h2 class="mat-mdc-dialog-title">Useraktivität</h2>

    <p>Sind Sie noch aktiv?</p>

    <mat-dialog-actions>
        <button
            mat-raised-button
            class="confirm-button"
            (click)="onConfirm()">
            Ja
        </button>
    </mat-dialog-actions>
</div>
