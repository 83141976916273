import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { EventsService } from '@app/core/services/event.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-user-idle',
    templateUrl: './user-idle.component.html',
    styleUrls: ['./user-idle.component.scss'],
    standalone: false,
})
export class UserIdleComponent implements OnInit, OnDestroy {
    @Input()
    restartIdle: boolean = false;
    @Output()
    triggerTimeout: EventEmitter<boolean> = new EventEmitter();

    @HostListener('window:keydown')
    @HostListener('window:mousedown')
    @HostListener('window:mousemove')
    checkUserActivity() {
        clearTimeout(this.timeoutId);
        this.checkTimeOut();
    }

    timeoutId: ReturnType<typeof setTimeout>;
    userInactive$: Subject<boolean> = new Subject();
    inactivityTimeout: number = 30 * 60 * 1000; // 30 minutes
    dialogRef: any;
    isVisible: boolean = false;

    constructor(private eventsService: EventsService) {
        this.checkTimeOut();
    }

    ngOnInit(): void {
        this.userInactive$.pipe(untilDestroyed(this)).subscribe(_ => {
            this.triggerTimeout.emit(false);
            this.showIdleDialog();
        });
        this.eventsService
            .subscribe('user-idle:confirmed')
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isVisible = false;
                this.triggerTimeout.emit(true);
                clearTimeout(this.timeoutId);
            });
    }

    ngOnDestroy(): void {
        clearTimeout(this.timeoutId);
    }

    checkTimeOut() {
        this.timeoutId = setTimeout(
            () => this.userInactive$.next(true),
            this.inactivityTimeout
        );
    }

    showIdleDialog(): void {
        if (this.isVisible) {
            return;
        }
        this.isVisible = true;
        this.eventsService.publish('user-idle:show');
        /*if (this.dialogRef) return;
        this.dialogRef = this.dialog.open(UserIdlePopupComponent, {
            size: 'sm',
            closeButton: false,
            enableClose: false,
            minHeight: '100px',
        });
        this.dialogRef.afterClosed$.subscribe((result: any) => {
            if (result) {
                this.triggerTimeout.emit(true);
            }
            this.dialogRef = undefined;
        });*/
    }
}
