import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CheckoutBundleService } from '@app/consignor/services/bundle-checkout.service';
import { DlsFolderService } from '@app/core/services/backend-services/dls-folder-service/dls-folder.service';
import { PrintService } from '@app/core/services/client-services/print-service/print-service.service';
import { StorageService } from '@app/core/services/client-services/storage-service/storage.service';
import { ToastService } from '@app/core/services/toast.service';
import { CustomTranslateService } from '@app/core/services/translate.service';
import { RoutingEndpoints } from '@app/shared/models/routing/routing-endpoints';
import { SendEmailDto } from '@app/shared/models/service-model/send-email-dto';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { GetTourResponse } from 'api/models';
import { ExternalService } from 'api/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from 'rxjs';
import { TourCheckoutResponse } from '../../../../../api/models/tour-checkout-response';
import { TourResponse } from '../../../../../api/models/tour-response';
import { ToursService } from '../../../../../api/services/tours.service';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-send-tour-wallet-invite',
    templateUrl: './send-tour-wallet-invite.component.html',
    styleUrls: ['./send-tour-wallet-invite.component.scss'],
    providers: [DatePipe],
    standalone: false,
})
export class SendTourWalletInviteComponent implements OnInit {
    organizationSiteKey: string;
    invitationForm: UntypedFormGroup;
    barcodeElementType = 'url';
    linkQrCode: string;
    linkQrCodeWE: string;

    isCheckout: boolean = false;

    tour: TourResponse;
    checkOutTour: TourCheckoutResponse;
    tourData: GetTourResponse;

    currentLanguageCode: string;

    loading = false;

    lastInvited: string;
    storedTourKeys: string[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            tour: TourResponse;
            organizationSiteKey: string;
            organizationName: string;
            isCheckout: boolean;
        },
        private dialogRef: MatDialogRef<SendTourWalletInviteComponent>,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly router: Router,
        private readonly dlsFolderService: DlsFolderService,
        private translate: TranslateService,
        private toastr: ToastService,
        private readonly externalService: ExternalService,
        private printService: PrintService,
        private customTranslateService: CustomTranslateService,
        private datePipe: DatePipe,
        private checkoutService: CheckoutBundleService,
        private toursService: ToursService,
        private spinner: NgxSpinnerService,
        private translateService: TranslateService,
        private storageService: StorageService
    ) {}

    async ngOnInit() {
        if (this.storageService.itemExists('tourKeys')) {
            this.storedTourKeys =
                this.storageService.getItemAsObject('tourKeys');
        }

        this.organizationSiteKey = this.data.organizationSiteKey;
        this.tour = this.data.tour;
        this.isCheckout = this.data.isCheckout;
        this.initForm();
        if (this.isCheckout) {
            await this.checkoutTour();
        } else {
            this.getTour(this.tour._key);
            this.createBarcodeUrl(this.tour.accessToken!, this.tour._key);
        }
    }

    get uniqueConsignors(): string {
        const consignors: string[] = [];

        this.tourData.deliveryNotes.forEach(deliveryNote => {
            if (
                deliveryNote &&
                !consignors.includes(deliveryNote.consignorName)
            ) {
                consignors.push(deliveryNote.consignorName);
            }
        });

        if (consignors.length > 1) {
            return this.translate.instant('WALLET.MULTIPLE-CONSIGNORS');
        }
        return consignors[0];
    }

    get uniqueConsignees(): string {
        const consignees: string[] = [];

        this.tourData.deliveryNotes.forEach(deliveryNote => {
            if (
                deliveryNote &&
                !consignees.includes(deliveryNote.consigneeName)
            ) {
                consignees.push(deliveryNote.consigneeName);
            }
        });
        if (consignees.length > 1) {
            return this.translate.instant('WALLET.MULTIPLE-CONSIGNEES');
        }
        return consignees[0];
    }

    async getTour(tourKey: string) {
        await this.spinner.show();

        try {
            this.tourData = await lastValueFrom(
                this.toursService.getTour({
                    organizationSiteKey: this.organizationSiteKey,
                    tourKey: tourKey,
                })
            );
        } catch (error) {
            console.log(error);
        }
        await this.spinner.hide();
    }

    async checkoutTour() {
        await this.spinner.show();
        try {
            this.checkOutTour = await lastValueFrom(
                this.toursService.postToursCheckout({
                    organizationSiteKey: this.organizationSiteKey,
                    tourKey: this.tour._key,
                })
            );

            // Remove tourKey from LocalStorage
            if (this.storedTourKeys.length > 0) {
                const tourKeys = this.storedTourKeys.filter(
                    item => item !== this.tour._key
                );
                this.storageService.setItem('tourKeys', tourKeys);
            }

            this.toastr.success(
                this.translateService.instant(
                    'SPEDITEUR.TOASTS.TOUR-CHECKED-OUT'
                )
            );

            this.createBarcodeUrl(
                this.checkOutTour.accessToken,
                this.checkOutTour.tour._key
            );
        } catch {
            this.toastr.error(
                this.translateService.instant(
                    'SPEDITEUR.TOASTS.TOUR-CHECK-OUT-FAILED'
                )
            );
        }
        await this.spinner.hide();
    }

    /**
     * Creates the URL for QR-Code.
     */
    createBarcodeUrl(token: string, tourKey: string) {
        this.linkQrCode =
            window.location.origin +
            '/external/wallet-invite' +
            '?tour=true' +
            '&token=' +
            token +
            '&tourKey=' +
            tourKey;

        this.linkQrCodeWE = `${window.location.origin}/${
            RoutingEndpoints.consignee
        }/${RoutingEndpoints.checkin.split('/')[0]}/${tourKey}`;
    }

    /**
     * Initializes the form.
     * @private
     */
    private initForm(): void {
        this.invitationForm = this.formBuilder.group({
            email: ['', [Validators.email, Validators.required]],
        });
    }

    /**
     * Sends the mail.
     */
    onClickSendBarcodeData(): void {
        const mailDto: SendEmailDto = {
            emailaddress: this.invitationForm.controls.email.value,
        };
        //TODO: send tour qr code mail
        /*this.dlsFolderService
      .sendQRCodeMail(this.data.dlsDeliveryNoteBundle._key, mailDto)
      .subscribe(() => this.dialogRef.close());*/
    }

    onClickOpenBarScanComponent(): void {
        if (this.isCheckout) {
            if (!this.checkOutTour?.accessToken) {
                return;
            }
            if (this.checkOutTour.tour._key) {
                const url = this.router.serializeUrl(
                    this.router.createUrlTree(
                        ['/external/' + RoutingEndpoints.wallet_invite],
                        {
                            queryParams: {
                                tour: true,
                                token: this.checkOutTour.accessToken,
                            },
                        }
                    )
                );
                window.open(url, '_blank');
            }
        } else {
            if (!this.tour.accessToken) {
                return;
            }

            const url = this.router.serializeUrl(
                this.router.createUrlTree(
                    ['/external/' + RoutingEndpoints.wallet_invite],
                    {
                        queryParams: {
                            tour: true,
                            token: this.tour.accessToken,
                        },
                    }
                )
            );
            window.open(url, '_blank');
        }
    }

    /**
   Get base64 string from QR code and open print dialog
   */
    onPrintQRCodeCanvas() {
        const el = <HTMLElement>document.getElementById('qrcode');
        const canvas = <HTMLCanvasElement>el?.firstChild;
        const dataUrl = canvas.toDataURL();
        const dateCreated = this.datePipe.transform(
            this.tour.dateCreated,
            'short'
        );
        const header = `<div class="meta-folder" style="margin-left: 12px; margin-top: 60px">Tour-Nr.: ${this.tour?.tourNumber} erstellt am : ${dateCreated} </div><br>
    <div class="meta" style="margin-left: 12px;"><span class="bold">Spediteur:</span> ${this.data.organizationName}</div><br>`;
        const style =
            '.bold { font-weight: bold}' +
            '.meta-folder { font-family: "Arial"; font-size: 20px; font-weight: 700} }' +
            '.meta { font-family: "Arial"; font-size: 18px; padding-top: 10px; padding-bottom: 10px; line-height: 40px; font-weight: 400}';
        this.printService.printImage(dataUrl, header, style);
    }

    async onPrintAllDeliveryNotes() {
        // if (!this.checkOutTour) return;

        const token: string | undefined = this.checkOutTour
            ? this.checkOutTour.accessToken
            : this.tour.accessToken;

        if (!token) return;
        const blob = await lastValueFrom(
            this.externalService.externalGetTourGateDeliveryNotePdfs({
                token: token,
            })
        );

        this.printService.printPdf(blob);
    }

    async sendInvitation(): Promise<void> {
        this.loading = true;
        try {
            await lastValueFrom(
                this.toursService.sendTourWalletInviteMailToDriver({
                    organizationSiteKey: this.data.organizationSiteKey,
                    tourKey: this.data.tour._key,
                    body: {
                        emailAddress:
                            this.invitationForm.controls.email.value.trim(),
                    },
                })
            );

            this.lastInvited = this.invitationForm.controls.email.value;

            this.toastr.success(
                this.translate.instant('SEND-WALLET-INVITE.SUCCESS')
            );
        } catch {
            this.toastr.error(this.translate.instant('GENERAL-ERROR'));
        }

        this.loading = false;
    }

    close() {
        this.dialogRef.close();
    }
}
