import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-change-name-dialog',
    templateUrl: './change-name-dialog.component.html',
    styleUrls: ['./change-name-dialog.component.scss'],
    standalone: false,
})
export class ChangeNameDialogComponent {
    name: string = '';
    originalName: string = '';
    isTourEdit: boolean = false;
    isCheckout: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: { name: string; isTourEdit: boolean; isCheckout: boolean },
        private dialogRef: MatDialogRef<ChangeNameDialogComponent>
    ) {
        this.name = data.name;
        this.originalName = data.name;
        this.isTourEdit = data.isTourEdit;
    }

    skip() {
        if (this.isTourEdit) {
            this.dialogRef.close(this.originalName);
        } else {
            this.dialogRef.close();
        }
    }

    saveAndClose() {
        this.dialogRef.close(this.name);
    }

    isEditTourConfirmButtonDisabled(): boolean {
        return this.name === this.originalName;
    }
}
