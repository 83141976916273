import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '@app/shared/material/material.module';
import { QrCodeModule } from 'ng-qrcode';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { TranslateModule } from '@ngx-translate/core';

import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component';
import { TableFilterComponent } from './components/table/table-filter/table-filter.component';
import { SignatureFieldComponent } from './components/signature-field/signature-field.component';
import { QuestionDialogComponent } from './components/question-dialog/question-dialog.component';
import { CarrierExchangeComponent } from './components/carrier-exchange/carrier-exchange.component';
import { SendWalletInviteComponent } from '@app/shared/components/send-wallet-invite/send-wallet-invite.component';
import { DeliveryNotesComponent } from './components/delivery-notes/delivery-notes.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';

import { TranslateDiscrepancyReasonsPipe } from './pipes/translate-discrepancy-reasons.pipe';
import { DeliveryNoteNumberPipe } from './pipes/delivery-note-number.pipe';
import { TranslateDiscrepancyUnitsPipe } from '@app/shared/pipes/translate-discrepancy-units.pipe';
import { FilterByNamePipe } from './pipes/filter-by-name.pipe';
import { FilterByDfnPipe } from './pipes/filter-by-dfn.pipe';

import { OutlinedDirective } from './directives/outlined.directive';
import { BolderEntryDirective } from '@app/shared/directives/bolder-entry.directive';
import { DragDropDirective } from './directives/drag-drop.directive';
import { SelectDisplayedColsDialogComponent } from './components/select-displayed-cols-dialog/select-displayed-cols-dialog.component';
import { LoadcarrierSwapComponent } from '@app/consignor/pages/create-new-folder/loadcarrier-swap/loadcarrier-swap.component';
import { PersistentTableSortDirective } from './directives/persistent-table-sort.directive';
import { IncludesPipe } from './pipes/includes.pipe';
import { UserIdleComponent } from './components/user-idle/user-idle.component';
import { DownloadNoteButtonComponent } from './components/download-note-button/download-note-button.component';
import { TourDeliveryNotesComponent } from './components/delivery-notes/tour-delivery-notes/tour-delivery-notes.component';
import { SendTourWalletInviteComponent } from './components/send-wallet-invite/send-tour-wallet-invite/send-tour-wallet-invite.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { ChangeNameDialogComponent } from './components/change-name-dialog/change-name-dialog.component';
import { WalletComponentModule } from './components/wallet/wallet.module';
import { FileInputAccessor } from './directives/file-input-accessor.directive';
import { UsersComponent } from './components/users/users.component';
import { FilterUserSitesComponent } from './components/filter-user-sites/filter-user-sites.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ShowPdfDialogComponent } from './components/show-pdf-dialog/show-pdf-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AttachmentCounterComponent } from './components/attachment-counter/attachment-counter.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { UserIdlePopupComponent } from './components/user-idle/user-idle-popup.component';
import { ViewBundleDialogComponent } from './components/view-bundle-dialog/view-bundle-dialog.component';
import { ViewTourDialogComponent } from './components/view-tour-dialog/view-tour-dialog.component';
import { CustomTooltipComponent } from './components/custom-tooltip/custom-tooltip.component';
import { OneButtonDialogComponent } from './components/one-button-dialog/one-button-dialog.component';
import { OverviewButtonsComponent } from './components/overview-buttons/overview-buttons.component';
import { IdleDialogComponent } from './components/idle-dialog/idle-dialog.component';

const declarations = [
    BolderEntryDirective,
    ModalDialogComponent,
    TableFilterComponent,
    SignatureFieldComponent,
    DeliveryNoteNumberPipe,
    TranslateDiscrepancyReasonsPipe,
    TranslateDiscrepancyUnitsPipe,
    QuestionDialogComponent,
    FilterByDfnPipe,
    CarrierExchangeComponent,
    SendWalletInviteComponent,
    OutlinedDirective,
    DeliveryNotesComponent,
    FilterByNamePipe,
    ConfirmationDialogComponent,
    DragDropDirective,
    SelectDisplayedColsDialogComponent,
    LoadcarrierSwapComponent,
    PersistentTableSortDirective,
    IncludesPipe,
    UserIdleComponent,
    DownloadNoteButtonComponent,
    TourDeliveryNotesComponent,
    SendTourWalletInviteComponent,
    AlertDialogComponent,
    ChangeNameDialogComponent,
    FileInputAccessor,
    UsersComponent,
    FilterUserSitesComponent,
    PageNotFoundComponent,
    ShowPdfDialogComponent,
    AttachmentCounterComponent,
    FeedbackComponent,
    UserIdlePopupComponent,
    OverviewButtonsComponent,
    IdleDialogComponent,
];
const imports = [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    AngularSignaturePadModule,
    FormsModule,
    QrCodeModule,
    NgxSkeletonLoaderModule,
    NgxExtendedPdfViewerModule,
    TranslateModule,
    WalletComponentModule,
    MatDialogModule,
];
@NgModule({
    declarations: [
        ...declarations,
        ViewBundleDialogComponent,
        ViewTourDialogComponent,
        CustomTooltipComponent,
        OneButtonDialogComponent,
    ],
    imports: [...imports],
    exports: [...declarations, ...imports],
})
export class SharedModule {}
