import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { StorageService } from '@app/core/services/client-services/storage-service/storage.service';
import { UtilsService } from '@app/core/services/client-services/utils-service/utils.service';
import { EventsService } from '@app/core/services/event.service';
import { ToastService } from '@app/core/services/toast.service';
import { Role } from '@app/shared/models/authentication/role';
import { AttachmentResponse } from '@app/shared/models/business-model/attachment-response';
import { LoadCarrierExchangeDto } from '@app/shared/models/service-model/load-carrier-exchange-dto';
import { FileService } from '@app/shared/services/file.service';
import { environment } from '@env/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import {
    ConsigneeAttachmentResponse,
    DeliveryNote,
    GetLoadCarrierTemplatesResponse,
    LoadCarrier,
    LoadCarrierOperation,
    LoadCarrierTemplate,
} from 'api/models';
import {
    AttachmentsService,
    ConsigneeLoadCarrierFilesService,
    DeliveryNotesService,
    LoadCarriersService,
    LoadCarrierTemplatesService,
    PalletTicketsService,
} from 'api/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from 'rxjs';
import { SessionService } from '@app/core/services/client-services/session-service/session.service';
import { CheckinService } from '@app/consignee/services/checkin.service';

export interface CarrierChange {
    isValid: boolean;
    deliveryNoteKey: string;
}

export interface AttachmentList {
    attachmentInfo: AttachmentResponse;
}

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-loadcarrier-swap',
    templateUrl: './loadcarrier-swap.component.html',
    styleUrls: ['./loadcarrier-swap.component.scss'],
    standalone: false,
})
export class LoadcarrierSwapComponent implements OnInit {
    @Input() nameOfControl: string;
    @Input() carrierExchangeInitialOpen = true;
    @Input() organizationSiteKey: string;
    @Input() deliveryNoteBundleKey: string;
    @Input() deliveryNote: DeliveryNote;
    @Input() loadCarriers: LoadCarrier[];
    @Input() orgType: Role = Role.CONSIGNOR;

    @ViewChild(MatExpansionPanel) panel!: MatExpansionPanel;
    @ViewChild('pdfDialog', { static: true }) pdfDialog: TemplateRef<any>;

    @Output()
    setProgress: EventEmitter<number> = new EventEmitter<number>();

    @Output()
    carriersGotChanged: EventEmitter<CarrierChange> =
        new EventEmitter<CarrierChange>();

    @Output()
    carriersGotUpdated: EventEmitter<void> = new EventEmitter<void>();

    isConsignee: boolean = false;
    loadCarrierSwapForm: UntypedFormGroup;
    formSaved = false;
    loadCarrier = true;
    initialized = false;
    blobData: Blob;
    imgSrcData: any;
    allCarrierTypes: LoadCarrierTemplate[];
    carrierOperations = LoadCarrierOperation;
    dplAttachments: AttachmentList[] = [];
    dplContentType: string;

    public selectedAttachmentFiles: File[] = [];
    public selectedPalletTicketFiles: File[] = [];

    env = environment;
    isAlive = true;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private attachmentsService: AttachmentsService,
        private storageService: StorageService,
        private translateService: TranslateService,
        private toastrService: ToastService,
        private dialog: MatDialog,
        private loadCarrierService: LoadCarriersService,
        private spinner: NgxSpinnerService,
        private utilService: UtilsService,
        private fileService: FileService,
        private palletTicketsService: PalletTicketsService,
        private consigneeLoadCarrierFilesService: ConsigneeLoadCarrierFilesService,
        private eventsService: EventsService,
        private deliveryNotesService: DeliveryNotesService,
        private loadCarrierTemplatesService: LoadCarrierTemplatesService,
        private sessionService: SessionService,
        private checkinService: CheckinService
    ) {}

    async ngOnInit() {
        this.loadCarrierSwapForm = this.formBuilder.group({});

        this.initForm();
        await this.getAllCarrierTypes();

        if (this.orgType === Role.CONSIGNOR) {
            this.getLoadCarriersOfthisDeliveryNote();
            this.getDeliveryNoteAttachments();
        }
        if (this.orgType === Role.CONSIGNEE) {
            this.isConsignee = true;
            this.getLoadCarriersConsigneeOfThisDeliveryNote();
            this.getLoadCarrierConsigneeAttachments();
            this.getPalettTickets();
        }
    }

    async refreshDeliveryNote() {
        const deliveryNote = await lastValueFrom(
            this.deliveryNotesService.getSingleDeliveryNoteMetaData({
                deliveryNoteKey: this.deliveryNote._key,
            })
        );

        this.deliveryNote = deliveryNote;
    }

    /**
     * Gets all carrier types.
     */
    async getAllCarrierTypes() {
        const orgKey = this.sessionService.getSession().organizations[0]._key;
        try {
            const response: GetLoadCarrierTemplatesResponse =
                await lastValueFrom(
                    this.loadCarrierTemplatesService.getLoadCarrierTemplates({
                        organizationKey: orgKey,
                    })
                );
            this.storageService.setItem('allCarrierTypes', response);
            this.allCarrierTypes = response;
        } catch (error) {
            this.allCarrierTypes =
                this.storageService.getItemAsObject('allCarrierTypes');
        }
    }

    /**
     * Initializes the form parts for the carrier exchange
     * @private
     */
    private initForm(): void {
        this.loadCarrierSwapForm.addControl(
            this.nameOfControl,
            this.formBuilder.array([])
        );
    }

    /**
     * Loads already existing carrier swaps from API and adds formarrays
     */
    async getLoadCarriersOfthisDeliveryNote(): Promise<void> {
        const data = await lastValueFrom(
            this.loadCarrierService.getLoadCarriers({
                organizationSiteKey: this.organizationSiteKey,
                deliveryNoteBundleKey: this.deliveryNoteBundleKey,
                deliveryNoteKey: this.deliveryNote._key,
            })
        );

        this.pushCarriersToForm(data);
    }

    async getLoadCarriersConsigneeOfThisDeliveryNote(): Promise<void> {
        const data = await lastValueFrom(
            this.loadCarrierService.getConsigneeLoadCarriers({
                organizationSiteKey: this.organizationSiteKey,
                deliveryNoteKey: this.deliveryNote._key,
            })
        );

        this.pushCarriersToForm(data);
    }

    pushCarriersToForm(data: LoadCarrier[]): void {
        data.forEach(item => {
            const loadCarrier = item;
            const templateKey = this.allCarrierTypes.filter(
                key => key.name === item.name
            )[0]._key;
            const operation = loadCarrier.operation as LoadCarrierOperation;

            const carrierObj: LoadCarrierExchangeDto = {
                templateKey: templateKey.toString(),
                operation: loadCarrier.operation,
                quantity: loadCarrier.quantity,
                damaged: loadCarrier.damaged,
                notice: loadCarrier.notice,
                name: '',
            };
            this.carrierExchangesFormArray.push(
                this.createCarrierExchange(operation, carrierObj)
            );
        });

        this.formSaved = true;

        this.emitCarriersGotChanged();
        this.loadCarrier = data.length > 0;
        this.initialized = true;

        this.loadCarrierSwapForm.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.formSaved = false;
                this.emitCarriersGotChanged();
            });
    }

    private emitCarriersGotChanged(): void {
        this.carriersGotChanged.emit({
            isValid: this.formSaved,
            deliveryNoteKey: this.deliveryNote._key,
        });
    }

    /**
     * Adds the initial state to the form array
     */
    addLoadCarrier(): void {
        this.carrierExchangesFormArray.clear();
        this.addCarrierExchange(LoadCarrierOperation.Adoption);
        this.loadCarrier = true;
    }

    /**
     * Adds the new carrier exchange input fields to the form array
     */
    addCarrierExchange(carrierExchangeOp?: LoadCarrierOperation): void {
        this.formSaved = false;
        this.emitCarriersGotChanged();
        this.carrierExchangesFormArray.push(
            this.createCarrierExchange(LoadCarrierOperation.Adoption)
        );
        this.carrierExchangesFormArray.push(
            this.createCarrierExchange(LoadCarrierOperation.Delivery)
        );
        this.carrierExchangesFormArray.markAllAsTouched();
    }

    /**
     * Removes the carrier exchange input fields from the form array
     * @param index
     */
    removeCarrierExchange(index: number): void {
        this.formSaved = false;
        this.carrierExchangesFormArray.removeAt(index);
        this.loadCarrier = this.carrierExchangesFormArray.length !== 0;
        this.emitCarriersGotChanged();
    }

    /**
     * Creates a new group of carrier exchange input fields.
     */
    private createCarrierExchange(
        carrierExchangeOp?: LoadCarrierOperation,
        obj?: LoadCarrierExchangeDto
    ): UntypedFormGroup {
        const foundType = this.allCarrierTypes.find(
            type => type.name === obj?.name
        );

        const templateKey =
            obj?.templateKey || (foundType ? foundType._key : null);

        return this.formBuilder.group({
            templateKey: [templateKey || '', Validators.required],
            operation: [carrierExchangeOp, Validators.required],
            quantity: [obj?.quantity, [Validators.required, Validators.min(1)]],
            damaged: [obj?.damaged || false],
            notice: [obj?.notice || '', Validators.maxLength(500)],
        });
    }

    /**
     * Returns the form array of the carrier exchanges.
     */
    get carrierExchangesFormArray(): UntypedFormArray {
        return this.loadCarrierSwapForm.get(
            this.nameOfControl
        ) as UntypedFormArray;
    }

    async saveLoadCarrierConsignor(): Promise<void> {
        await lastValueFrom(
            this.loadCarrierService.putLoadCarrier({
                organizationSiteKey: this.organizationSiteKey,
                deliveryNoteBundleKey: this.deliveryNoteBundleKey,
                deliveryNoteKey: this.deliveryNote._key,
                body: this.loadCarrierSwapForm.value.swapCarrier,
            })
        );

        this.carriersGotUpdated.emit();
    }

    async saveLoadCarrierConsignee(): Promise<void> {
        await this.spinner.show();
        try {
            await lastValueFrom(
                this.loadCarrierService.putConsigneeLoadCarriers({
                    organizationSiteKey: this.organizationSiteKey,
                    deliveryNoteKey: this.deliveryNote._key,
                    body: this.loadCarrierSwapForm.value.swapCarrier,
                })
            );
            this.toastrService.success(
                this.translateService.instant(
                    'OUTGOING-GOODS.NEW-BUNDLE.SWAP.SAVING.SUCCESS'
                )
            );
            this.checkinService.setCheckinForComponents(
                (this.checkinService._checkin.dateConsigneeSigned = 0)
            );
        } catch {
            this.toastrService.error(
                this.translateService.instant(
                    'OUTGOING-GOODS.NEW-BUNDLE.SWAP.SAVING.FAILED'
                )
            );
        } finally {
            this.carriersGotUpdated.emit();
            await this.spinner.hide();
        }
    }

    async saveLoadCarrier(): Promise<void> {
        try {
            if (this.orgType === Role.CONSIGNOR)
                await this.saveLoadCarrierConsignor();
            if (this.orgType === Role.CONSIGNEE)
                await this.saveLoadCarrierConsignee();

            this.formSaved = true;
            this.emitCarriersGotChanged();
        } catch (e) {
            this.toastrService.error(
                this.translateService.instant('GENERAL-ERROR')
            );
        }
    }

    closePanel(): void {
        this.panel.close();
    }

    /**
     * Removes an attachment from delivery note
     * @param index
     */
    async removeAttachment(index: number, attachmentInfo: AttachmentResponse) {
        if (this.isConsignee) {
            if (attachmentInfo.contentType.includes('pdf')) {
                try {
                    await lastValueFrom(
                        this.palletTicketsService.deletePalletTicket({
                            organizationSiteKey: this.organizationSiteKey,
                            deliveryNoteKey: this.deliveryNote._key,
                            attachmentKey: attachmentInfo._key,
                        })
                    );

                    this.dplAttachments.splice(index, 1);
                    this.toastrService.success(
                        this.translateService.instant(
                            'OUTGOING-GOODS.NEW-BUNDLE.SWAP.ATTACHMENTS.DELETED'
                        )
                    );
                    this.checkinService.setCheckinForComponents(
                        (this.checkinService._checkin.dateConsigneeSigned = 0)
                    );
                } catch {
                    this.toastrService.error(
                        this.translateService.instant(
                            'OUTGOING-GOODS.NEW-BUNDLE.SWAP.ATTACHMENTS.DELETE-ERROR'
                        )
                    );
                }
            } else {
                try {
                    await lastValueFrom(
                        this.consigneeLoadCarrierFilesService.deleteConsigneeLoadCarrierFile(
                            {
                                organizationSiteKey: this.organizationSiteKey,
                                deliveryNoteKey: this.deliveryNote._key,
                                attachmentKey: attachmentInfo._key,
                            }
                        )
                    );

                    this.dplAttachments.splice(index, 1);
                    this.toastrService.success(
                        this.translateService.instant(
                            'OUTGOING-GOODS.NEW-BUNDLE.SWAP.ATTACHMENTS.DELETED'
                        )
                    );
                } catch {
                    this.toastrService.error(
                        this.translateService.instant(
                            'OUTGOING-GOODS.NEW-BUNDLE.SWAP.ATTACHMENTS.DELETE-ERROR'
                        )
                    );
                }
            }
            this.carriersGotUpdated.emit();
        } else {
            try {
                await lastValueFrom(
                    this.attachmentsService.deleteAttachment({
                        organizationSiteKey: this.organizationSiteKey,
                        deliveryNoteBundleKey: this.deliveryNoteBundleKey,
                        deliveryNoteKey: this.deliveryNote._key,
                        attachmentKey: attachmentInfo._key,
                    })
                );
                this.dplAttachments.splice(index, 1);
                this.toastrService.success(
                    this.translateService.instant(
                        'OUTGOING-GOODS.NEW-BUNDLE.SWAP.ATTACHMENTS.DELETED'
                    )
                );
            } catch {
                this.toastrService.error(
                    this.translateService.instant(
                        'OUTGOING-GOODS.NEW-BUNDLE.SWAP.ATTACHMENTS.DELETE-ERROR'
                    )
                );
            }
        }
        this.refreshDeliveryNote();
    }

    async selectAttachmentFile(event: any) {
        const files = event.target.files;
        for (let file of files) {
            if (!file) {
                continue;
            }
            const { fileBlob, fileName, fileType } =
                await this.fileService.prepareFile(file);

            if (!this.fileService.isFileTypeAllowed(fileType!)) {
                continue;
            }
            // file = await this.heicService.convertHEIC(file);
            if (this.utilService.checkMaxFilesize(file.size)) {
                //check if consignee
                this.spinner.show();
                if (this.isConsignee) {
                    try {
                        let response: ConsigneeAttachmentResponse;
                        const requestBody = {
                            organizationSiteKey: this.organizationSiteKey,
                            deliveryNoteKey: this.deliveryNote._key,
                            fileName: fileName,
                            body: fileBlob,
                        };
                        switch (fileType) {
                            case 'image/heif':
                                response = await lastValueFrom(
                                    this.consigneeLoadCarrierFilesService.postConsigneeLoadCarrierFileToDeliveryNote$Heif(
                                        requestBody
                                    )
                                );
                                break;
                            case 'image/heic':
                                response = await lastValueFrom(
                                    this.consigneeLoadCarrierFilesService.postConsigneeLoadCarrierFileToDeliveryNote$Heic(
                                        requestBody
                                    )
                                );
                                break;
                            case 'image/png':
                                response = await lastValueFrom(
                                    this.consigneeLoadCarrierFilesService.postConsigneeLoadCarrierFileToDeliveryNote$Png(
                                        requestBody
                                    )
                                );
                                break;
                            case 'image/jpeg':
                                response = await lastValueFrom(
                                    this.consigneeLoadCarrierFilesService.postConsigneeLoadCarrierFileToDeliveryNote$Jpeg(
                                        requestBody
                                    )
                                );
                                break;
                        }

                        this.selectedAttachmentFiles.push(file);
                        const obj: AttachmentList = {
                            attachmentInfo: response!,
                        };

                        this.dplAttachments.push(obj);
                        this.toastrService.success(
                            this.translateService.instant(
                                'OUTGOING-GOODS.NEW-BUNDLE.SWAP.ATTACHMENTS.SAVED'
                            )
                        );
                        this.checkinService.setCheckinForComponents(
                            (this.checkinService._checkin.dateConsigneeSigned = 0)
                        );
                        this.spinner.hide();
                    } catch (error) {
                        this.toastrService.error(
                            this.translateService.instant(
                                'OUTGOING-GOODS.NEW-BUNDLE.SWAP.ATTACHMENTS.ERROR'
                            )
                        );
                        this.spinner.hide();
                    }
                } else {
                    try {
                        const requestBody = {
                            organizationSiteKey: this.organizationSiteKey,
                            deliveryNoteBundleKey: this.deliveryNoteBundleKey,
                            deliveryNoteKey: this.deliveryNote._key,
                            fileName: fileName,
                            body: fileBlob,
                        };
                        let response: AttachmentResponse;
                        switch (fileType) {
                            case 'image/heif':
                                response = await lastValueFrom(
                                    this.attachmentsService.postAttachmentToDeliveryNote$Heif(
                                        requestBody
                                    )
                                );
                                break;
                            case 'image/heic':
                                response = await lastValueFrom(
                                    this.attachmentsService.postAttachmentToDeliveryNote$Heic(
                                        requestBody
                                    )
                                );
                                break;
                            case 'image/png':
                                response = await lastValueFrom(
                                    this.attachmentsService.postAttachmentToDeliveryNote$Png(
                                        requestBody
                                    )
                                );
                                break;
                            case 'image/jpeg':
                                response = await lastValueFrom(
                                    this.attachmentsService.postAttachmentToDeliveryNote$Jpeg(
                                        requestBody
                                    )
                                );
                                break;
                        }
                        this.selectedAttachmentFiles.push(file);
                        const obj: AttachmentList = {
                            attachmentInfo: response!,
                        };

                        this.dplAttachments.push(obj);
                        this.toastrService.success(
                            this.translateService.instant(
                                'OUTGOING-GOODS.NEW-BUNDLE.SWAP.ATTACHMENTS.SAVED'
                            )
                        );

                        this.spinner.hide();
                    } catch (error) {
                        this.toastrService.error(
                            this.translateService.instant(
                                'OUTGOING-GOODS.NEW-BUNDLE.SWAP.ATTACHMENTS.ERROR'
                            )
                        );
                        this.spinner.hide();
                    }
                }
            }
        }
        event.target.value = null;
        this.eventsService.publish(
            'attachment-counter:update',
            this.deliveryNote
        );
        this.refreshDeliveryNote();
        this.carriersGotUpdated.emit();
    }

    async selectPalletTicketFile(event: any) {
        this.spinner.show();
        const files = event.target.files;
        for (let file of files) {
            if (!this.checkPalletTicketFileExists(file.name)) {
                try {
                    const response = await lastValueFrom(
                        this.palletTicketsService.postPalletTicketToDeliveryNote(
                            {
                                fileName: file.name,
                                organizationSiteKey: this.organizationSiteKey,
                                deliveryNoteKey: this.deliveryNote._key,
                                body: file,
                            }
                        )
                    );
                    this.selectedPalletTicketFiles.push(file);
                    const obj: AttachmentList = {
                        attachmentInfo: response,
                    };

                    this.dplAttachments.push(obj);
                    this.toastrService.success(
                        this.translateService.instant(
                            'OUTGOING-GOODS.NEW-BUNDLE.SWAP.ATTACHMENTS.SAVED'
                        )
                    );
                    this.checkinService.setCheckinForComponents(
                        (this.checkinService._checkin.dateConsigneeSigned = 0)
                    );
                    this.spinner.hide();
                } catch (error) {
                    this.toastrService.error(
                        this.translateService.instant(
                            'OUTGOING-GOODS.NEW-BUNDLE.SWAP.ATTACHMENTS.ERROR'
                        )
                    );
                    this.spinner.hide();
                }
            }
        }
        this.refreshDeliveryNote();
        this.carriersGotUpdated.emit();
    }

    async getDeliveryNoteAttachments() {
        const data = await lastValueFrom(
            this.attachmentsService.getAttachmentsForDeliveryNote({
                organizationSiteKey: this.organizationSiteKey,
                deliveryNoteBundleKey: this.deliveryNoteBundleKey,
                deliveryNoteKey: this.deliveryNote._key,
            })
        );

        data.forEach(item => {
            const obj: AttachmentList = {
                attachmentInfo: item,
            };
            this.dplAttachments.push(obj);
        });
    }

    async getLoadCarrierConsigneeAttachments(): Promise<void> {
        const data = await lastValueFrom(
            this.consigneeLoadCarrierFilesService.getConsigneeLoadCarrierFilesMetadata(
                {
                    organizationSiteKey: this.organizationSiteKey,
                    deliveryNoteKey: this.deliveryNote._key,
                }
            )
        );

        data.forEach(item => {
            const obj: AttachmentList = {
                attachmentInfo: item,
            };
            this.dplAttachments.push(obj);
        });
    }

    async getPalettTickets(): Promise<void> {
        const data = await lastValueFrom(
            this.palletTicketsService.getPalletTicketsForDeliveryNote({
                organizationSiteKey: this.organizationSiteKey,
                deliveryNoteKey: this.deliveryNote._key,
            })
        );

        data.forEach(item => {
            const obj: AttachmentList = {
                attachmentInfo: item,
            };
            this.dplAttachments.push(obj);
        });
    }

    async downloadAttachment(attachmentKey: string, dplContentType: string) {
        let data!: Blob;

        if (this.orgType === 'consignor') {
            data = await lastValueFrom(
                this.attachmentsService.getAttachment$Png({
                    organizationSiteKey: this.organizationSiteKey,
                    deliveryNoteBundleKey: this.deliveryNoteBundleKey,
                    deliveryNoteKey: this.deliveryNote._key,
                    attachmentKey,
                })
            );
        }

        if (this.orgType === 'consignee') {
            data = await lastValueFrom(
                this.consigneeLoadCarrierFilesService.getConsigneeLoadCarrierFile$Png(
                    {
                        organizationSiteKey: this.organizationSiteKey,
                        deliveryNoteKey: this.deliveryNote._key,
                        attachmentKey: attachmentKey,
                    }
                )
            );
        }

        this.blobData = data;
        this.dplContentType = dplContentType;

        if (dplContentType.includes('image')) {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onload = _event => {
                this.imgSrcData = reader.result;
            };
        }

        this.dialog.open(this.pdfDialog, {
            minWidth: '92%',
            minHeight: '90%',
            disableClose: true,
        });
    }

    private checkAttachmentFileExists(filename: string): boolean {
        let check = false;
        if (this.selectedAttachmentFiles.length > 0) {
            check = this.selectedAttachmentFiles.some(f => f.name === filename);
        }
        return check;
    }

    private checkPalletTicketFileExists(filename: string): boolean {
        let check = false;
        if (this.selectedPalletTicketFiles.length > 0) {
            check = this.selectedPalletTicketFiles.some(
                f => f.name === filename
            );
        }
        return check;
    }
}
