import { Directive, AfterViewInit, Input } from '@angular/core';
import { MatSortable, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SessionService } from '@app/core/services/client-services/session-service/session.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy({
    checkProperties: true,
})
@Directive({
    selector: '[appPersistentTableSort]',
    standalone: false,
})
export class PersistentTableSortDirective implements AfterViewInit {
    @Input() dataSource: MatTableDataSource<any>;
    @Input() persistentTableId: string;

    currentSort: Sort;

    constructor(private sessionService: SessionService) {}

    ngAfterViewInit(): void {
        if (!this.persistentTableId) {
            console.warn(
                'Persistent table directive requires a persistentTableId'
            );
        } else {
            window.setTimeout(() => {
                this.initialSetup();
                this.listenToStateChangeEvents();
            }, 100);
        }
    }

    private writeSortToLocalstorage() {
        const uid = this.sessionService.getSession()._key;

        console.log(uid);

        const key = `${uid}-persistentsort_${this.persistentTableId}`;

        if (!this.currentSort.direction) localStorage.removeItem(key);
        else {
            const content = JSON.stringify(this.currentSort);
            localStorage.setItem(key, content);
        }
    }

    private loadSortFromLocalStorage() {
        const uid = this.sessionService.getSession()._key;
        const contents = localStorage.getItem(
            `${uid}-persistentsort_${this.persistentTableId}`
        );
        if (contents) {
            this.currentSort = JSON.parse(contents);
        }
    }

    private listenToStateChangeEvents(): void {
        if (this.dataSource.sort) {
            this.dataSource
                .sort!.sortChange.pipe(untilDestroyed(this))
                .subscribe((sortChange: Sort) => {
                    this.currentSort = sortChange;
                    this.writeSortToLocalstorage();
                });
        }
    }

    private async initialSetup(): Promise<void> {
        this.loadSortFromLocalStorage();
        if (this.currentSort) {
            const sortable: MatSortable = {
                id: this.currentSort.active,
                start: this.currentSort.direction,
                disableClear: false,
            };
            this.dataSource.sort!.sort(sortable);
        }
    }
}
