import { Component, Inject, OnDestroy, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
    standalone: false,
})
export class ConfirmationDialogComponent implements OnDestroy {
    constructor(
        private renderer: Renderer2,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            msg: string;
            icon: string;
            headline: string;
            action: string;
        },
        private dialogRef: MatDialogRef<ConfirmationDialogComponent>
    ) {
        this.renderer.addClass(document.body, 'disable-scroll-and-height');
        this.renderer.addClass(
            document.documentElement,
            'disable-scroll-and-height'
        );
    }

    /**
     * Closes the dialog and sets the clsoing result.
     * @param result
     */
    closeWithResult(result: string): void {
        this.cleanup();
        this.dialogRef.close(result);
    }

    ngOnDestroy() {
        this.cleanup();
    }
    private cleanup(): void {
        this.renderer.removeClass(document.body, 'disable-scroll-and-height');
        this.renderer.removeClass(
            document.documentElement,
            'disable-scroll-and-height'
        );
    }
}
