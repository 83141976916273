import {
    NgSignaturePadOptions,
    SignaturePadComponent,
} from '@almothafar/angular-signature-pad';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostListener,
    Inject,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { WINDOW } from '@app/shared/services/window.token';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-signature-field',
    templateUrl: './signature-field.component.html',
    styleUrls: ['./signature-field.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SignatureFieldComponent),
            multi: true,
        },
    ],
    standalone: false,
})
export class SignatureFieldComponent
    implements ControlValueAccessor, AfterViewInit
{
    @ViewChild('signature')
    public signaturePad: SignaturePadComponent;

    @ViewChild('signatureWrapper') signatureWrapper: ElementRef<HTMLDivElement>;

    public _signature: any = null;
    public propagateChange: Function;

    @Input() canvasWidth: number = 380;
    @Input() canvasHeight: number = 200;
    @Input() showTruck: boolean = false;
    @Input() send: boolean = false;
    @Input() clearSignature: boolean = false;
    @Input() padId = '';
    @Input() showPullSignature = false;
    @Output() signatureCleared: EventEmitter<boolean> = new EventEmitter();
    @Output() signatureDrawCompleted: EventEmitter<boolean> =
        new EventEmitter();
    @Output() signatureDrawStarted: EventEmitter<boolean> = new EventEmitter();
    @Output() pullSignature: EventEmitter<boolean> = new EventEmitter();

    canvas: HTMLCanvasElement;
    wrapper: any;

    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
        //  this.resizeCanvas();
    }

    loadSignature(): void {
        this.signaturePad?.clear();
        this.pullSignature.emit(true);
    }

    public signaturePadOptions: NgSignaturePadOptions = {
        canvasWidth: this.canvasWidth,
        canvasHeight: this.canvasHeight,
    };
    constructor(
        public elRef: ElementRef,
        @Inject(WINDOW) public readonly window: any
    ) {
        this.window = window as Window;
    }

    ngAfterViewInit(): void {
        this.signaturePad?.clear();
        // this.resizeCanvas();
    }

    get signature(): any {
        return this._signature;
    }

    set signature(value: any) {
        this._signature = value;
        if (typeof this.propagateChange === 'function') {
            this.propagateChange(this.signature);
        }
    }

    public writeValue(value: any): void {
        if (!value) {
            return;
        }
        this._signature = value;
        this.signaturePad?.fromDataURL(this.signature);
    }

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(): void {
        // no-op
    }

    drawComplete(event: MouseEvent | Touch) {
        this.signature = this.signaturePad.toDataURL();
        this.signatureDrawCompleted.emit(true);
    }

    drawStart(event: MouseEvent | Touch) {
        this.signatureDrawStarted.emit(true);
    }

    public clear(): void {
        this.signaturePad.clear();
        this.signature = '';
        this.signatureCleared.emit(true);
    }

    private resizeCanvas() {
        this.signaturePadOptions.canvasWidth = this.signaturePadWidth() - 2;
        this.signaturePad.set('canvasWidth', this.signaturePadWidth() - 2);
    }

    private signaturePadWidth() {
        const width = this.signatureWrapper.nativeElement.offsetWidth;
        if (width < 250) {
            return 250;
        }

        return 350; //width; // DIGLIEF-2644
    }
}
