import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialog,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DlsFolderService } from '@app/core/services/backend-services/dls-folder-service/dls-folder.service';
import { PrintService } from '@app/core/services/client-services/print-service/print-service.service';
import { ToastService } from '@app/core/services/toast.service';
import { CustomTranslateService } from '@app/core/services/translate.service';
import { RoutingEndpoints } from '@app/shared/models/routing/routing-endpoints';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { CarrierDeliveryNoteBundleOverview } from 'api/models';
import { lastValueFrom } from 'rxjs';
import { CheckoutBundleService } from '../../../consignor/services/bundle-checkout.service';
import { DeliveryNoteBundleWithOrgSiteKey } from '@app/consignor/services/consignor.service';
import { DeliveryNoteBundlesService } from '../../../../api/services/delivery-note-bundles.service';
import { OrganizationSitesService } from '../../../../api/services/organization-sites.service';
import { ExternalService } from 'api/services';
import { FeedbackComponent } from '../feedback/feedback.component';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-send-wallet-invite-modal',
    templateUrl: './send-wallet-invite.component.html',
    styleUrls: ['./send-wallet-invite.component.scss'],
    providers: [DatePipe],
    standalone: false,
})
export class SendWalletInviteComponent implements OnInit {
    invitationForm: UntypedFormGroup;
    barcodeElementType = 'url';
    linkQrCode: string;
    deliveryNoteBundle:
        | DeliveryNoteBundleWithOrgSiteKey
        | CarrierDeliveryNoteBundleOverview;

    currentLanguageCode: string;

    loading = false;

    lastInvited: string;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            dlsDeliveryNoteBundle:
                | DeliveryNoteBundleWithOrgSiteKey
                | CarrierDeliveryNoteBundleOverview;
            organizationSiteKey: string;
            isCarrier: boolean;
        },
        private dialogRef: MatDialogRef<SendWalletInviteComponent>,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly router: Router,
        private translate: TranslateService,
        private toastr: ToastService,
        private readonly externalService: ExternalService,
        private printService: PrintService,
        private customTranslateService: CustomTranslateService,
        private datePipe: DatePipe,
        private organizationSitesService: OrganizationSitesService,
        private deliveryNoteBundleService: DeliveryNoteBundlesService,
        private dialog: MatDialog
    ) {
        this.currentLanguageCode = this.customTranslateService.currentLang;
    }

    async ngOnInit() {
        this.deliveryNoteBundle = this.data.dlsDeliveryNoteBundle;
        await this.initForm();
        this.createBarcodeUrl();
    }

    /**
     * Creates the URL for QR-Code.
     */
    createBarcodeUrl(): void {
        if (!('accessToken' in this.deliveryNoteBundle)) return;
        this.linkQrCode = `${window.location.origin}/external/wallet-invite?token=${this.deliveryNoteBundle.accessToken}&deliverNoteBundleKey=${this.data.dlsDeliveryNoteBundle._key}`;
    }

    /**
     * Initializes the form.
     * @private
     */
    private async initForm() {
        this.invitationForm = this.formBuilder.group({
            email: ['', [Validators.email, Validators.required]],
        });

        //download delivery note bundle to get the email address
        if (this.data.isCarrier) {
            const bundle = await lastValueFrom(
                this.deliveryNoteBundleService.getCarrierBundle({
                    organizationSiteKey: this.data.organizationSiteKey,
                    deliveryNoteBundleKey: this.deliveryNoteBundle._key,
                })
            );
            /*this.invitationForm.controls.email.setValue(
                bundle.carrierEmailAddressOutgoing
            );*/
        } else if (
            isDeliveryNoteBundleWithOrgSiteKey(this.deliveryNoteBundle)
        ) {
            const bundle = await lastValueFrom(
                this.deliveryNoteBundleService.getBundle({
                    organizationSiteKey: this.deliveryNoteBundle.orgSiteKey,
                    deliveryNoteBundleKey: this.deliveryNoteBundle._key,
                })
            );

            this.invitationForm.controls.email.setValue(
                bundle.carrierEmailAddressOutgoing
            );
        }

        /*if (isDeliveryNoteBundleWithOrgSiteKey(this.deliveryNoteBundle)) {
            const bundle = await lastValueFrom(
                this.deliveryNoteBundleService.getBundle({
                    organizationSiteKey: this.deliveryNoteBundle.orgSiteKey,
                    deliveryNoteBundleKey: this.deliveryNoteBundle._key,
                })
            );

            this.invitationForm.controls.email.setValue(
                bundle.carrierEmailAddressOutgoing
            );
        } else {
            const bundle = await lastValueFrom(
                this.deliveryNoteBundleService.getBundle({
                    organizationSiteKey: this.data.organizationSiteKey,
                    deliveryNoteBundleKey: this.deliveryNoteBundle._key,
                })
            );

            this.invitationForm.controls.email.setValue(
                bundle.carrierEmailAddressOutgoing
            );
        }*/
    }

    onClickOpenBarScanComponent(): void {
        if (!('accessToken' in this.deliveryNoteBundle)) return;

        if (this.data.dlsDeliveryNoteBundle._key) {
            const url = this.router.serializeUrl(
                this.router.createUrlTree(
                    ['/external/' + RoutingEndpoints.wallet_invite],
                    {
                        queryParams: {
                            token: this.deliveryNoteBundle.accessToken,
                            deliverNoteBundleKey:
                                this.data.dlsDeliveryNoteBundle._key,
                        },
                    }
                )
            );
            window.open(url, '_blank');
        }
        this.close();
    }
    /**
   Get base64 string from QR code and open print dialog
   */
    onPrintQRCodeCanvas() {
        const el = <HTMLElement>document.getElementById('qrcode');
        const canvas = <HTMLCanvasElement>el?.firstChild;
        const dataUrl = canvas.toDataURL();
        const dateCreated = this.datePipe.transform(
            this.deliveryNoteBundle.dateCreated,
            'short'
        );
        const header = `<div class="meta-folder" style="margin-left: 12px; margin-top: 60px">Mappen-Nr.: ${this.deliveryNoteBundle?.bundleNumber} erstellt am : ${dateCreated} </div><br>
    <div class="meta" style="margin-left: 12px;"><span class="bold">Empfänger:</span> ${this.deliveryNoteBundle.consigneeName}</div><br>
    <div class="meta" style="margin-left: 12px;"><span class="bold">Spediteur:</span> ${this.deliveryNoteBundle.carrierName}</div><br>
    <div class="meta" style="margin-left: 12px; margin-bottom: 30px;"><span class="bold">Versender:</span> ${this.deliveryNoteBundle.consignorName}</div>`;
        const style =
            '.bold { font-weight: bold}' +
            '.meta-folder { font-family: "Arial"; font-size: 20px; font-weight: 700} }' +
            '.meta { font-family: "Arial"; font-size: 18px; padding-top: 10px; padding-bottom: 10px; line-height: 40px; font-weight: 400}';
        this.printService.printImage(dataUrl, header, style);
    }

    async onPrintAllDeliveryNotes() {
        if (!('accessToken' in this.deliveryNoteBundle)) return;

        const blob = await lastValueFrom(
            this.externalService.externalGetGateDeliveryNotePdfs({
                token: this.deliveryNoteBundle.accessToken,
            })
        );

        this.printService.printPdf(blob);
    }

    async sendInvitation(): Promise<void> {
        this.loading = true;

        try {
            await lastValueFrom(
                this.organizationSitesService.sendWalletInviteMailToDriver({
                    organizationSiteKey: this.data.organizationSiteKey,
                    deliveryNoteBundleKey: this.data.dlsDeliveryNoteBundle._key,
                    body: {
                        emailAddress:
                            this.invitationForm.controls.email.value.trim(),
                    },
                })
            );

            this.lastInvited = this.invitationForm.controls.email.value;

            this.toastr.success(
                this.translate.instant('SEND-WALLET-INVITE.SUCCESS')
            );
        } catch {
            this.toastr.error(this.translate.instant('GENERAL-ERROR'));
        }

        this.loading = false;
    }

    close() {
        /*const triggerDate = moment(response.lastFeedback)
                .add(60, 'hours')
                .format();
            const now = moment().format();

            this.triggerFeedback = moment(triggerDate).isBefore(now);
            if (localStorage.getItem('hideRating') !== 'true') {
                this.rate().then(() => {
                    this.closeDialog(result.accessToken);
                });
            } else {
                this.closeDialog(result.accessToken);
            }*/

        if (localStorage.getItem('hideRating') !== 'true') {
            this.rate().then(() => {
                this.dialogRef.close();
            });
        } else {
            this.dialogRef.close();
        }
    }

    rate() {
        return new Promise(resolve => {
            const dialogRef = this.dialog.open(FeedbackComponent, {
                minWidth: '380px',
                disableClose: true,
            });
            dialogRef.afterClosed().subscribe(() => {
                resolve('done');
            });
        });
    }
}

function isDeliveryNoteBundleWithOrgSiteKey(
    object: any
): object is DeliveryNoteBundleWithOrgSiteKey {
    return 'orgSiteKey' in object;
}
