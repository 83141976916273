import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AlertDialogData {
    message: string;
}

@Component({
    selector: 'app-alert-dialog',
    templateUrl: './alert-dialog.component.html',
    styleUrls: ['./alert-dialog.component.scss'],
    standalone: false,
})
export class AlertDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: AlertDialogData) {}
}
