import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'includes',
    standalone: false,
})
export class IncludesPipe implements PipeTransform {
    transform(haystack: string, needle: string): boolean {
        return haystack.includes(needle);
    }
}
