import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

interface ColumnData {
    name: string;
    shouldDisplay: boolean;
    disabled?: boolean;
}

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-select-displayed-cols-dialog',
    templateUrl: './select-displayed-cols-dialog.component.html',
    styleUrls: ['./select-displayed-cols-dialog.component.scss'],
    standalone: false,
})
export class SelectDisplayedColsDialogComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        private data: {
            allColumns: string[];
            displayedColumns: string[];
            keyLabel?: string;
            isTours?: boolean;
        },
        private dialogRef: MatDialogRef<SelectDisplayedColsDialogComponent>
    ) {}

    columnData: ColumnData[] = [];
    keyLabel?: string;
    isTours: boolean | undefined = false;

    amountOfDisabled = 0;

    ngOnInit(): void {
        this.data.allColumns.forEach(col => {
            let shouldDisplay = false;
            let disabled = false;
            if (col == 'details' || col == 'checked' || col == 'actions') {
                disabled = true;
                this.amountOfDisabled++;
            }

            if (this.data.displayedColumns.includes(col)) shouldDisplay = true;
            this.columnData.push({
                name: col,
                shouldDisplay,
                disabled,
            });
        });

        this.keyLabel = this.data.keyLabel;
        this.isTours = this.data.isTours;
    }

    get amountOfChecked(): number {
        return (
            this.columnData.filter(col => col.shouldDisplay).length -
            this.amountOfDisabled
        );
    }

    reset() {
        this.dialogRef.close({ reset: true });
    }

    closeDialog() {
        const colsToDisplay = this.columnData.filter(col => col.shouldDisplay);
        const cols = colsToDisplay.map(e => e.name);
        this.dialogRef.close(cols);
    }
}
