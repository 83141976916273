<div>
    <div class="tw-flex tw-items-center tw-justify-between">
        <div class="mat-headline-6">
            {{ 'SPEDITEUR.VIEW-TOUR.TOUR' | translate }}
            {{ tour.tourNumber }}
        </div>

        <div>
            <button
                *ngIf="tour.deliveryNoteKeys.length"
                (click)="downloadTour()"
                mat-icon-button
                [matTooltip]="'SPEDITEUR.VIEW-TOUR.DOWNLOAD' | translate">
                <mat-icon fontSet="material-symbols-outlined"
                    >file_download</mat-icon
                >
            </button>

            <button
                mat-icon-button
                mat-dialog-close>
                <mat-icon fontSet="material-symbols-outlined">close</mat-icon>
            </button>
        </div>
    </div>

    <mat-divider></mat-divider>

    <div *ngIf="currentlyViewedPdf">
        <ngx-extended-pdf-viewer
            [src]="currentlyViewedPdf"
            aria-label="pdf-viewer"
            [showToolbar]="false">
        </ngx-extended-pdf-viewer>
    </div>

    <div
        *ngIf="!tour.deliveryNoteKeys.length"
        class="tw-flex tw-h-96 tw-items-center tw-justify-center">
        {{ 'OUTGOING-GOODS.NEW-BUNDLE.NO-NOTES' | translate }}
    </div>

    <div
        *ngIf="tour.deliveryNoteKeys.length && !currentlyViewedPdf"
        class="tw-flex tw-h-96 tw-items-center tw-justify-center">
        {{ 'SPEDITEUR.VIEW-TOUR.LOADING' | translate }}
    </div>
</div>
