import {
    Component,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { SessionService } from '@app/core/services/client-services/session-service/session.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeliveryNote, SingleDeliveryNote } from 'api/models';
import { EventsService } from '@app/core/services/event.service';
import { DeliveryNotesService } from 'api/services';
import { lastValueFrom } from 'rxjs';

@UntilDestroy({
    checkProperties: true,
})
@Component({
    selector: 'app-attachment-counter[deliveryNote]',
    templateUrl: './attachment-counter.component.html',
    styleUrls: ['./attachment-counter.component.scss'],
    standalone: false,
})
export class AttachmentCounterComponent implements OnInit {
    @Input() attachmentLimit: number = 30;
    @Input() deliveryNote: DeliveryNote;

    isLoading: boolean = false;
    isVisible: boolean;

    @ViewChild('wrapper', { static: false })
    wrapper: ElementRef<HTMLDivElement>;

    @HostListener('window:scroll', ['$event'])
    isInView() {
        if (this.wrapper) {
            const rect = this.wrapper.nativeElement.getBoundingClientRect();
            const topShown = rect.top >= 0;
            const bottomShown = rect.bottom <= window.innerHeight;
            this.isVisible = topShown && bottomShown;
        }
    }

    constructor(
        private sessionService: SessionService,
        private eventsService: EventsService,
        private deliverNotesService: DeliveryNotesService
    ) {}

    async ngOnInit() {
        await this.load();

        this.eventsService
            .subscribe('attachment-counter:update')
            .pipe(untilDestroyed(this))
            .subscribe(async (deliveryNote: DeliveryNote) => {
                await this.load(deliveryNote);
            });
    }

    async load(deliveryNote?: DeliveryNote) {
        this.isInView();
        /*        if (deliveryNote) {
            this.deliveryNote = deliveryNote;
        }
        if (!this.isVisible) {
            return;
        }*/
        this.isLoading = true;
        try {
            const quotas = await this.sessionService.getQuotas();
            this.attachmentLimit =
                quotas?.maxQuotas?.maxDeliveryNoteAttachments || 30;

            if (deliveryNote) {
                if (
                    this.deliveryNote &&
                    deliveryNote._key === this.deliveryNote._key
                ) {
                    // only reload delivery note if global event delivery note is the same as this class field's delivery note
                    const deliveryNote: SingleDeliveryNote =
                        await lastValueFrom(
                            this.deliverNotesService.getSingleDeliveryNoteMetaData(
                                {
                                    deliveryNoteKey: this.deliveryNote._key,
                                }
                            )
                        );
                    this.deliveryNote.attachmentsCount =
                        deliveryNote.attachmentsCount;
                }
            } else {
                const deliveryNote: SingleDeliveryNote = await lastValueFrom(
                    this.deliverNotesService.getSingleDeliveryNoteMetaData({
                        deliveryNoteKey: this.deliveryNote._key,
                    })
                );
                this.deliveryNote.attachmentsCount =
                    deliveryNote.attachmentsCount;
            }
        } catch (error) {}
        this.isLoading = false;
    }
}
