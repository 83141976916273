import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-idle-dialog',
    templateUrl: './idle-dialog.component.html',
    styleUrls: ['./idle-dialog.component.scss'],
    standalone: false,
})
export class IdleDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<IdleDialogComponent>,
        private translate: TranslateService
    ) {
        this.dialogRef.disableClose = true;
    }

    onConfirm(): void {
        this.dialogRef.close(true);
    }
}
