import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UtilsService } from '@app/core/services/client-services/utils-service/utils.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { takeWhile } from 'rxjs/operators';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-modal-dialog',
    templateUrl: './modal-dialog.component.html',
    standalone: false,
})
export class ModalDialogComponent implements OnInit, OnDestroy {
    /** Title text for the modal header. */
    @Input() title?: string;
    /** sub-Title text for the modal header. */
    @Input() subtitle?: string;
    /** Template for the modal body */
    @Input() bodyTemplate?: TemplateRef<Element>;
    /** Template for the modal footer */
    @Input() footerTemplate?: TemplateRef<Element>;

    @Input() headerTemplate?: TemplateRef<Element>;
    /** Flag if the close button should be shown */
    @Input() showCloseButton: boolean;

    isAlive: boolean;

    constructor(
        private utilsService: UtilsService,
        private dialogRef: MatDialogRef<ModalDialogComponent>
    ) {
        this.isAlive = true;
        this.showCloseButton = true;
    }

    ngOnInit(): void {
        this.utilsService.closeButtonVisibilty$
            .pipe(untilDestroyed(this))
            .subscribe(status => {
                this.showCloseButton = status;
            });
    }

    ngOnDestroy(): void {
        this.isAlive = false;
    }

    close(): void {
        this.dialogRef.close();
    }
}
