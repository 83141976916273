import { HttpResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { ToastService } from '@app/core/services/toast.service';
import { DeliveryNotes } from '@app/shared/models/business-model/delivery-notes';
import { ServiceEndpoints } from '@app/shared/models/service-model/service-endpoints';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { DeliveryNote } from 'api/models';
import {
    CheckinsService,
    DeliveryNotesService,
    PdfService,
} from 'api/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from 'rxjs';
import { OrgType } from '../delivery-notes/delivery-notes.component';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-download-note-button',
    templateUrl: './download-note-button.component.html',
    styleUrls: ['./download-note-button.component.scss'],
    standalone: false,
})
export class DownloadNoteButtonComponent {
    @Input() deliveryNote: DeliveryNotes | DeliveryNote;
    @Input() organizationSiteKey: string;
    @Input() orgType: OrgType = OrgType.Consignor;
    @Input() fetchCompletePDF: boolean;

    constructor(
        private spinner: NgxSpinnerService,
        private toastr: ToastService,
        private translate: TranslateService,
        private deliveryNotesService: DeliveryNotesService,
        private checkinService: CheckinsService,
        private pdfService: PdfService
    ) {}

    get shouldFetchSigned(): boolean {
        if (
            'deliveryNoteBundleKey' in this.deliveryNote &&
            !this.deliveryNote.deliveryNoteBundleKey
        )
            return false;

        if (!('deliveryNoteBundleStatus' in this.deliveryNote)) return false;

        return (
            this.deliveryNote.deliveryNoteBundleStatus === 'sent' ||
            this.deliveryNote.deliveryNoteBundleStatus === 'closed'
        );
    }

    private downloadBlob(blob: Blob, filename: string): void {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        a.href = url;
        a.download = filename;
        a.click();
    }

    async downloadDeliveryNote(): Promise<void> {
        try {
            await this.spinner.show();
            await this.downloadDeliveryNoteWithoutBundleKey();
        } catch (error) {
            console.error(error);
            this.toastr.error(this.translate.instant('ERROR.DOWNLOAD-NOTE'));
        } finally {
            this.spinner.hide();
        }
    }

    async downloadDeliveryNoteWithoutBundleKey(): Promise<void> {
        try {
            let blobData;
            let result;
            let responseHeaders;

            switch (this.orgType) {
                case OrgType.Consignor:
                    if (this.fetchCompletePDF) {
                        const response = await lastValueFrom(
                            this.pdfService.getConsignorDeliveryNoteCombinedPdf$Response(
                                {
                                    organizationSiteKey:
                                        this.organizationSiteKey,
                                    deliveryNoteKey: this.deliveryNote._key,
                                }
                            )
                        );
                        blobData = response.body;
                        responseHeaders = response.headers;
                    } else {
                        const response = await lastValueFrom(
                            this.deliveryNotesService.getDeliveryNoteFromPool$Response(
                                {
                                    organizationSiteKey:
                                        this.organizationSiteKey,
                                    deliveryNoteKey: this.deliveryNote._key,
                                }
                            )
                        );
                        blobData = response.body;
                        responseHeaders = response.headers;
                    }

                    break;
                case OrgType.Consignee:
                    const responseData = await lastValueFrom(
                        this.checkinService.getConsigneeDeliveryNoteCombinedPdf$Response(
                            {
                                organizationSiteKey: this.organizationSiteKey,
                                deliveryNoteKey: this.deliveryNote._key,
                            }
                        )
                    );
                    result = responseData.body;
                    responseHeaders = responseData.headers;
                    break;
                case OrgType.Carrier:
                    const data = await lastValueFrom(
                        this.pdfService.getCarrierDeliveryNoteCombinedPdf$Response(
                            {
                                organizationSiteKey: this.organizationSiteKey,
                                deliveryNoteKey: this.deliveryNote._key,
                            }
                        )
                    );
                    result = data.body;
                    responseHeaders = data.headers;
                    break;
            }
            if (result) {
                const buffer = await result.arrayBuffer();
                blobData = new Blob([
                    new Uint8Array(buffer, undefined, result.size),
                ]);
            }

            if (!blobData) throw new Error('No blob data found');

            // the downloadable pdf is renamed serverside eg. glnNumber_deliverynoteNumber.pdf, the new filename is part of the reponse header
            const glnFilename: string =
                responseHeaders.get('content-disposition')?.split('=')[1] ||
                this.deliveryNote.fileName;

            this.downloadBlob(blobData, glnFilename);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async downloadDeliveryNoteWithBundleKey(): Promise<void> {
        if (!('deliveryNoteBundleKey' in this.deliveryNote)) return;
        try {
            let response: HttpResponse<Blob>;
            let endpointToUse: string;

            /*
            switch (this.orgType) {
                case OrgType.Consignor:
                    endpointToUse = this.shouldFetchSigned
                        ? ServiceEndpoints.getDeliveryNoteSignedPdfbyKey
                        : ServiceEndpoints.getDeliveryNotePdfbyKey;
                    break;
                case OrgType.Consignee:
                    endpointToUse = this.shouldFetchSigned
                        ? ServiceEndpoints.getDeliveryNoteSignedPdfbyKeyConsignee
                        : ServiceEndpoints.getDeliveryNotePdfbyKeyConsignee;
                    break;
                case OrgType.Carrier:
                    endpointToUse =
                        ServiceEndpoints.getDeliveryNotePdfbyKeyCarrier;
            }

            if (!endpointToUse) throw new Error('No endpoint found');

            response = await lastValueFrom(
                this.deliveryNotesService.getDeliveryNoteInBundleByKeyFull(
                    this.organizationSiteKey,
                    this.deliveryNote.deliveryNoteBundleKey as string,
                    this.deliveryNote._key,
                    endpointToUse
                )
            );

            if (response) {
                // the downloadable pdf is renamed serverside eg. glnNumber_deliverynoteNumber.pdf, the new filename is part of the reponse header
                const glnFilename: string =
                    response.headers
                        .get('content-disposition')
                        ?.split('=')[1] || this.deliveryNote.fileName;

                const blob = response.body as Blob;

                this.downloadBlob(blob, glnFilename);

            // TODO
            }
            */
        } catch (error) {
            throw error;
        } finally {
            this.spinner.hide();
        }
    }
}
