import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-one-button-dialog',
    templateUrl: './one-button-dialog.component.html',
    styleUrls: ['./one-button-dialog.component.scss'],
    standalone: false,
})
export class OneButtonDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            msg: string;
            headline: string;
            showCloseButton?: boolean;
            headlineFontSize?: number;
            msgFontSize?: number;
            buttonFontSize?: number;
            buttonHeight?: number;
        },
        private dialogRef: MatDialogRef<OneButtonDialogComponent>
    ) {
        this.data.showCloseButton = this.data.showCloseButton ?? true;
    }

    close(): void {
        this.dialogRef.close();
    }
}
