<div>
    <div class="tw-flex tw-items-center tw-justify-between">
        <div class="mat-headline-6">
            {{ 'OUTGOING-GOODS.BUNDLE' | translate }}
            {{ deliveryNoteBundle.bundleNumber }}
        </div>

        <div>
            <button
                *ngIf="deliveryNoteBundle.numberOfDeliveryNotes"
                (click)="downloadDeliveryBundle()"
                mat-icon-button
                [matTooltip]="
                    'OUTGOING-GOODS.FOLDER-VIEW.HOVER-TEXT.DOWNLOAD-BUNDLE'
                        | translate
                ">
                <mat-icon fontSet="material-symbols-outlined"
                    >file_download</mat-icon
                >
            </button>

            <button
                mat-icon-button
                mat-dialog-close>
                <mat-icon fontSet="material-symbols-outlined">close</mat-icon>
            </button>
        </div>
    </div>

    <mat-list>
        <mat-list-item class="tw-mb-0 tw-flex tw-items-center tw-h-18">
            <div class="tw-flex tw-items-center">
                <mat-icon
                    mat-list-icon
                    class="tw-mr-2">
                    <img
                        src="./assets/images/direction.svg"
                        height="18" />
                </mat-icon>
                <div>
                    <div class="tw-font-medium">
                        {{ deliveryNoteBundle.consignorName }}
                    </div>
                    <div class="tw-opacity-80 tw-text-sm">
                        {{
                            'OUTGOING-GOODS.FOLDER-VIEW.TABLE-HEADER.CONSIGNOR-NAME'
                                | translate
                        }}
                    </div>
                </div>
            </div>
        </mat-list-item>

        <mat-list-item class="tw-mb-0 tw-flex tw-items-center tw-h-18">
            <div class="tw-flex tw-items-center">
                <mat-icon
                    mat-list-icon
                    class="tw-mr-2">
                    <img
                        src="./assets/images/truck.svg"
                        height="18" />
                </mat-icon>
                <div>
                    <div class="tw-font-medium">
                        {{ deliveryNoteBundle.carrierName }}
                    </div>
                    <div class="tw-opacity-80 tw-text-sm">
                        {{
                            'OUTGOING-GOODS.FOLDER-VIEW.TABLE-HEADER.CARRIER-NAME'
                                | translate
                        }}
                    </div>
                </div>
            </div>
        </mat-list-item>

        <mat-list-item class="tw-mb-0 tw-flex tw-items-center tw-h-18">
            <div class="tw-flex tw-items-center">
                <mat-icon
                    mat-list-icon
                    class="tw-mr-2">
                    <img
                        src="./assets/images/folder_checked.svg"
                        height="18" />
                </mat-icon>
                <div>
                    <div class="tw-font-medium">
                        {{ deliveryNoteBundle.consigneeName }}
                    </div>
                    <div class="tw-opacity-80 tw-text-sm">
                        {{
                            'OUTGOING-GOODS.FOLDER-VIEW.TABLE-HEADER.CONSIGNEE-NAME'
                                | translate
                        }}
                    </div>
                </div>
            </div>
        </mat-list-item>
    </mat-list>
    <mat-divider></mat-divider>

    <div *ngIf="currentlyViewedPdf">
        <ngx-extended-pdf-viewer
            [src]="currentlyViewedPdf"
            [height]="'100%'"
            [showToolbar]="false">
        </ngx-extended-pdf-viewer>
    </div>

    <div
        *ngIf="!deliveryNoteBundle.numberOfDeliveryNotes"
        class="tw-flex tw-h-96 tw-items-center tw-justify-center">
        {{ 'OUTGOING-GOODS.NEW-BUNDLE.NO-NOTES' | translate }}
    </div>

    <div
        *ngIf="deliveryNoteBundle.numberOfDeliveryNotes && !currentlyViewedPdf"
        class="tw-flex tw-h-96 tw-items-center tw-justify-center">
        {{ 'OUTGOING-GOODS.NEW-BUNDLE.LOADING' | translate }}
    </div>
</div>
