import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    constructor(private toastr: ToastrService) {}

    success(message: string, title?: string, options?: Partial<ToastrOptions>) {
        const defaultOptions: Partial<ToastrOptions> = {
            timeOut: 10000,
            progressBar: true,
            ...options,
        };
        this.toastr.success(message, title, defaultOptions);
    }

    error(message: string, title?: string, options?: Partial<ToastrOptions>) {
        const defaultOptions: Partial<ToastrOptions> = {
            timeOut: 0,
            extendedTimeOut: 0,
            tapToDismiss: false,
            closeButton: true,
            progressBar: true,
            ...options,
        };
        this.toastr.error(message, title, defaultOptions);
    }

    info(message: string, title?: string, options?: Partial<ToastrOptions>) {
        const defaultOptions: Partial<ToastrOptions> = {
            timeOut: 10000,
            progressBar: true,
            ...options,
        };
        this.toastr.info(message, title, defaultOptions);
    }

    warning(message: string, title?: string, options?: Partial<ToastrOptions>) {
        const defaultOptions: Partial<ToastrOptions> = {
            timeOut: 10000,
            progressBar: true,
            ...options,
        };
        this.toastr.warning(message, title, defaultOptions);
    }
}

interface ToastrOptions {
    timeOut: number;
    closeButton: boolean;
    progressBar: boolean;
    [key: string]: any;
}
