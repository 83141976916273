import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from './toast.service';

@Injectable({
    providedIn: 'root',
})
export class ErrorHandler {
    constructor(
        private toastService: ToastService,
        private translateService: TranslateService
    ) {}

    handleError(error: any) {
        console.log('error', error);
        if (error) {
            this.toastService.error(
                this.translateService.instant('GLOBAL.ERROR.UNSPECIFIED')
            );
        }
    }
}
