import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PrintService {
    private printWindow: Window | null = null;

    constructor() {}

    /**
     * Prints an image with optional header and custom styles
     * @param dataUrl - The image data URL
     * @param header - Optional header text
     * @param style - Optional custom CSS styles
     */
    printImage(dataUrl: string, header: string = '', style: string = '') {
        this.printWindow = window.open('', '_blank');
        if (!this.printWindow) return;

        const defaultStyle = `
      @media print {
        img { width: 27%; }
        .header { 
          font-size: 16px;
          margin-bottom: 10px;
          text-align: center;
        }
        @page { margin: 0.5cm; }
      }
    `;

        this.printWindow.document.write(`
      <!DOCTYPE html>
      <html>
        <head>
          <title>Print</title>
          <style>
            ${defaultStyle}
            ${style}
          </style>
        </head>
        <body>
          ${header ? `<div class="header">${header}</div>` : ''}
          <img src="${dataUrl}" alt="Print Image">
          <script>
            window.onload = function() {
              window.print();
              window.onafterprint = function() {
                window.close();
              };
            };
          </script>
        </body>
      </html>
    `);
        this.printWindow.document.close();
    }

    /**
     * Prints a PDF from base64 string
     * @param base64 - Base64 encoded PDF string
     */
    printPdfBase64(base64: string) {
        const binaryData = atob(base64);
        const byteArray = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
            byteArray[i] = binaryData.charCodeAt(i);
        }
        const file = new Blob([byteArray], { type: 'application/pdf' });
        this.printPdf(file);
    }

    /**
     * Prints a PDF from blob data
     * @param file - PDF file as Blob
     */
    printPdf(file: Blob) {
        const fileURL = URL.createObjectURL(file);
        const printWindow = window.open(fileURL);

        if (printWindow) {
            printWindow.onload = () => {
                printWindow.print();
                URL.revokeObjectURL(fileURL);
            };
        }
    }

    /**
     * Clean up method to close any open print windows
     */
    cleanup() {
        if (this.printWindow) {
            this.printWindow.close();
            this.printWindow = null;
        }
    }
}
